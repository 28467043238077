<template>
  <div class="box">
    <div class="color_content">
      <div class="content_div1" @click="theme('blue')"></div>
      <div class="content_div1 content_div2" @click="theme('glup')"></div>
      <div class="content_div1 content_div3" @click="theme('red')"></div>
      <div class="content_div1 content_div4" @click="theme('green')"></div>
      <div class="content_div1 content_div5" @click="theme('yellow')"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 点击对应的颜色块进行意见换肤
    theme(type) {
      // 将对应的数据存储到sessionStorage，方便后续使用
      sessionStorage.setItem("type", type);
      // 全局添加样式名称：data-theme，并在sessionStorage获取对应的样式名称的值。
      window.document.documentElement.setAttribute(
        "data-theme",
        sessionStorage.getItem("type")
      );
    },
  },
};
</script>

<style scoped>
.color_content {
  width: 550px;
  height: 120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff0000;
  border-radius: 2px;
  box-shadow: -2px 5px 12px 4px #f0f0f0;
}

.color_content .content_div1 {
  width: 80px;
  height: 80px;
  margin-right: 25px;
  background: #0056cc;
  cursor: pointer;
}
.color_content .content_div2 {
  background: #e426fd;
}
.color_content .content_div3 {
  background: #fd5451;
}
.color_content .content_div4 {
  background: #0dd78d;
}
.color_content .content_div5 {
  background: #feae4d;
  margin-right: 0px;
}
</style>
