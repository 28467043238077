<template>
  <div class="login">
    <div class="llg">
      <img src="../../public/image/logi/phlogo.png" class="llo" />
      <div class="llr">普华电力智能化运维平台管理端</div>
    </div>
    <div class="center">
      <div class="formImg">
        <div class="imgDiv">
          <img src="../../public/image/logi/c.png" class="logo" />
        </div>
        <div class="imgText"></div>
      </div>
      <div class="form" v-show="temp">
        <div class="formHeard">登录</div>
        <div class="xx">
          <div class="white"></div>
          <div class="whitee"></div>
        </div>
        <Input prefix="ios-person" placeholder="用户名" v-model="user" class="myInput" />
        <Input prefix="ios-lock" type="password" placeholder="密码" v-model="password" class="myInput myInput1" />
        <div class="loginBtn" @click="login">登录</div>
        <div class="xzzd" @click="autoRemember">
          <input type="checkbox" v-model="rememberMe" class="ccc" />
          <span> 下次自动登录 </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let Base64 = require('js-base64').Base64
import url from '../utils/api.js'
import { Debounce } from '../utils/debounce.js'
import { mapActions } from 'vuex'
import JSEncrypt from 'jsencrypt';
export default {
  name: 'login',
  data() {
    return {
      user: '',
      password: '',
      rememberMe: false,
      temp: true,
      rememberCheck: false,
    }
  },
beforeMount() {
   
},
  mounted: function() {
    document.title = '电力智能化运维平台2.0'
    const that = this
    document.onkeydown = function() {
      var key = window.event.keyCode
      if (key === 13) {
        that.login()
      }
    }

    if (this.common.get('rememberMe')) {
      this.rememberMe = true
    } else {
      this.rememberMe = false
    }
    this.actionOut()

  
    setTimeout(() => {
    sessionStorage.clear();
    sessionStorage.setItem('grant', 'in')
    }, 100);
 
  
    this.common.delete('token')
    if (this.common.get('rememberMe')) {
      let arr = {
        grant_type: 'refresh_token',
        refresh_token: this.common.get('refresh_token'),
      }
      this.$get(url.LOGIN, arr)
        .then(async (res) => {
          sessionStorage.setItem('grant', 'in')
          this.common.set('token', res['access_token'])
          this.common.set('reToken', res['refresh_token'])
          this.common.set('expiresin', parseInt(new Date().getTime() / 1000) + res['expires_in'] * 1)
          this.$Message.success('登录成功!')
          if (res['refresh_token']) {
            this.common.set('refresh_token', res['refresh_token'], 30)
          }

         await this.$get(url.USERINSIDE_LEFT_MENU)
            .then((res) => {
              let routerList = []
              res.forEach((el) => {
                el.children.forEach((item) => {
                  routerList.push(item.href)
                })
              })
              sessionStorage.setItem('routerList', JSON.stringify(routerList))
              this.actionMuen(res)
            })
            .catch((error) => {
              this.common.tokenIsTrue(this, error)
            })

         await this.$get(url.SYS_USERINSIDE_GETCURRENT)
            .then((res) => {
             
              this.actionUser(res)
            })
            .catch((error) => {
              this.common.tokenIsTrue(this, error)
            })

              this.getALARMContentTotal()
          document.onkeydown = undefined
          setTimeout(() => {
            this.$router.push({
              path: '/index',
            })
          }, 1000)
        })
        .catch((error) => {
          this.$Message.error(error.response.data.msg)
        })
    }
  },
  methods: {
    ...mapActions(['actionUser', 'actionMuen', 'actionOut']),
    login: Debounce(async function() {
      if (!this.user || !this.password) {
        return this.$Message.warning('用户名或密码不能为空！')
      }

     let publicKey=""
     await  this.$get(url.ENERGY_SYS_USERINSIDE_GETKEY)
            .then((res) => {
            publicKey =res
            })
            .catch((error) => {
              this.common.tokenIsTrue(this, error)
            })


      this.common.delete('token')
      // this.common.delete('grant')
      this.common.delete('reToken')
      this.common.delete('expiresin')


      let thisUser = {
        grant: 'in',
        username: this.user,
      }

      let thisUser1 = Base64.encode(JSON.stringify(thisUser))
      
 
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      let encryptedText = encrypt.encrypt(this.password);

      let arr = {
        grant_type: 'rsa_password',
        username: thisUser1,
        password: encryptedText,
        public_key: publicKey,
      }

      this.$get(url.LOGIN, arr)
        .then(async (res) => {
          sessionStorage.setItem('grant', 'in')
          this.common.set('token', res['access_token'])
          this.common.set('reToken', res['refresh_token'])
          this.common.set('expiresin', parseInt(new Date().getTime() / 1000) + res['expires_in'] * 1)
          this.$Message.success('登录成功!')
          if (res['refresh_token']) {
            this.common.set('refresh_token', res['refresh_token'], 30)
          }

         await this.$get(url.USERINSIDE_LEFT_MENU)
            .then((res) => {
             
              let routerList = []
              res.forEach((el) => {
                el.children.forEach((item) => {
                  routerList.push(item.href);
                });
              });
              sessionStorage.setItem("routerList", JSON.stringify(routerList));
           
              this.actionMuen(res);
            })
            .catch((error) => {
              this.common.tokenIsTrue(this, error)
            })

         await this.$get(url.SYS_USERINSIDE_GETCURRENT)
            .then((res) => {
            
              this.actionUser(res)
            })
            .catch((error) => {
              this.common.tokenIsTrue(this, error)
            })


       this.getALARMContentTotal()

          document.onkeydown = undefined
          setTimeout(() => {
            this.$router.push({
              path: '/index',
            })
          }, 500)
        })
        .catch((error) => {
          this.$Message.error(error.response.data.msg)
        })
    }, 1000),
    autoRemember() {
      this.rememberMe = !this.rememberMe
      this.common.set('rememberMe', this.rememberMe, 30)
    },
    getALARMContentTotal() {
    this.$get(url.ENERGY_AM_ALARMRECORD_CUMULATIVENUMBER,{stationIds: this.$store.state.stationIds,})
        .then((res) => {
       
          this.$store.state.ALARMContentTotal = res;
          localStorage.setItem("ALARMContentTotal",JSON.stringify(res))
        })
        .catch((error) => {
          this.common.tokenIsTrue(this, error)
        })
    },
  },
}
</script>
<style lang="less">
.myInput {
  .ivu-input {
    height: 0.4rem;
  }

  .ivu-input-inner-container > .ivu-input {
    height: 0.4rem;
  }

  .ivu-input-prefix > .ivu-icon {
    line-height: 0.4rem;
  }
}
</style>
<style scoped lang="less">
@color: rgba(241, 230, 230, 0);
@left: left;
@inline_b: inline-block;
@img: '../../public/image/';
@blue: #45c8dc;
@white: #ffffff;

.login {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  background-image: url('@{img}/logi/bj.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
  border-color: @color;
}

.xzzd {
  font-size: 12px;
  color: #ffffff;
  float: left;
  margin: 20px 0px 0px 0px;
}

.llr {
  color: #ffffff;
  font-size: 25px;
  float: right;
  margin: 7px 0px 0px 26px;
}

.llo {
  width: 210px;
  height: 51px;
}

.llg {
  float: left;
  margin: 30px 0px 0px 50px;
}

.center {
  width: 10rem;
  height: 3.7rem;
  margin: 2.1rem 0 0 3.2rem;
  // background-color: rgba(0, 10, 42, 0.45);
}

.formImg {
  width: 3.5rem;
  height: 100%;
  // padding-top: 0.84rem;
  padding-bottom: 1rem;
  position: relative;
  top: 21px;
  // left: 1.53rem;
}

.imgDiv {
  width: 4.42rem;
  height: 3.82rem;
  padding-left: 0.4rem;
}

.logo {
  width: 100%;
  height: 100%;
}

.imgText {
  font-family: MicrosoftYaHei-Bold;
  font-size: 0.3rem;
  font-weight: 600;
  font-stretch: normal;
  line-height: 2.34rem;
  color: #ffffff;
}

.form {
  width: 3.6rem;
  height: 3.79rem;
  // background-color: #ffffff;
  // border-top: 1px solid #fff;
  border-radius: 0.04rem;
  position: relative;
  top: -3.9rem;
  left: 9rem;
}

.formHeard {
  width: 100%;
  height: 0.21rem;
  margin: 0.5rem 0rem 0.08rem 0rem;
  text-align: left;
  font-family: MicrosoftYaHei-Bold;
  font-size: 0.2rem;
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.blue {
  width: 0.8rem;
  height: 0.03rem;
  margin: 0 auto 0.28rem auto;
  background-color: #0ccec3;
}

.xx {
  display: flex;
}

.white {
  width: 0.8rem;
  height: 0.03rem;
  margin: 0.23rem 0rem 0.28rem 0rem;
  background-color: #ffffff;
  text-align: left;
}

.whitee {
  width: 2.28rem;
  height: 0.01rem;
  margin: 0.24rem 0rem 0.28rem 0rem;
  background-color: #ffffff;
  text-align: right;
}

.myInput {
  width: 3.16rem;
  height: 0.4rem;
  background-color: #ffffff;
  border-radius: 0.04rem;
  margin: 0.2rem 0rem 0rem -0.49rem !important;
}

.myInput1 {
  margin-top: 0.2rem;
}

.ccc {
  margin: 0.02rem 0.1rem 0rem -0.02rem;
  float: left;
  width: 14px;
  height: 14px;
}

.loginBtn {
  width: 3.16rem;
  height: 0.4rem;
  background-color: #0ccec3;
  margin: 0.4rem 0rem 0rem -0.02rem;
  border-radius: 0.04rem;
  font-family: MicrosoftYaHei;
  font-size: 0.16rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0.4rem;
  letter-spacing: 0.04rem;
  color: #ffffff;
  cursor: pointer;
}

.loginBtn:hover {
  background-color: #0bc3b9;
}
</style>
