import getTokens from "./getToken";
export default {
  tokenIsTrue(vm, error) {
    console.log(vm,'===GG===',error);
    if (JSON.parse(JSON.stringify(error)).message.indexOf("401") != -1) {
      if (sessionStorage.getItem('grant') === 'out'){
        if (vm.$store.state.getToken) {
          this.delete("usertoken");
          vm.$store.dispatch("actionTempUser", false);
          if (this.get("userreToken")) {
            getTokens(vm);
          } else {
            vm.$Message.warning("登录超时，请重新登录！");
            if (sessionStorage.getItem("grant") === "out") {
              vm.$router.push({
                path: '/',
              })
            } else if(sessionStorage.getItem('grant') === 'in') {
              vm.$router.push({
                path: "/admin",
              });
            }
  
            vm.common.delete("userreToken");
            vm.common.delete("userexpiresin");
  
            sessionStorage.removeItem('StationName')
            sessionStorage.removeItem('StationId')
          }
        }
      } else {
        if (vm.$store.state.getToken) {
          this.delete("token");
          vm.$store.dispatch("actionTemp", false);
          if (this.get("reToken")) {
            getTokens(vm);
          } else {
            vm.$Message.warning("登录超时，请重新登录！");
            if (sessionStorage.getItem("grant") === "out") {
              vm.$router.push({
                path: '/',
              })
            } else if(sessionStorage.getItem('grant') === 'in') {
              vm.$router.push({
                path: "/admin",
              });
            }
  
            vm.common.delete("reToken");
            vm.common.delete("expiresin");
  
            sessionStorage.removeItem('StationName')
            sessionStorage.removeItem('StationId')
          }
        }
      }
     
    } else {
      vm.$Message.error(error.response.data.msg);
    }
  },

  set(name, value, days) {
    var d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    window.document.cookie =
      name + "=" + value + ";path=/;expires=" + d.toGMTString();
  },

  get(name) {
    var v = window.document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
  },

  delete: function(name) {
    this.set(name, "", -1);
  },

  getCurDay() {
    let nowdays = new Date();
    let year = nowdays.getFullYear();
    let month = nowdays.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    return `${year}-${month}-01`;
  },

  getNowTime() {
    let nowdays = new Date();
    let year = nowdays.getFullYear();
    let month = nowdays.getMonth() + 1;
    let day = nowdays.getDate();
    let hours = nowdays.getHours();
    let min = nowdays.getMinutes();
    month = month > 9 ? month : "0" + month;
    day = day > 9 ? day : "0" + day;
    hours = hours > 9 ? hours : "0" + hours;
    min = min > 9 ? min : "0" + min;
    return `${year}-${month}-${day} ${hours}:${min}`;
  },
  getNowTime1(value) {
    value = new Date(value);
    let year = value.getFullYear();
    let month = value.getMonth() + 1;
    let day = value.getDate();
    let hours = value.getHours();
    let min = value.getMinutes();
    let ss = value.getSeconds();

    month = month > 9 ? month : "0" + month;
    day = day > 9 ? day : "0" + day;
    hours = hours > 9 ? hours : "0" + hours;
    min = min > 9 ? min : "0" + min;
    ss = ss > 9 ? ss : "0" + ss;
    return `${year}-${month}-${day} ${hours}:${min}:${ss}`;
  },

  getMdate(value2) {
    let nowdays = new Date(value2);
    let year = nowdays.getFullYear();
    let month = nowdays.getMonth() + 1;
    let ri = nowdays.getDate();
    month = month > 9 ? month : "0" + month;
    ri = ri > 9 ? ri : "0" + ri;
    return `${year}-${month}-${ri}`;
  },
  MQTTConnected(_this) {
    if (_this.$mqtt && _this.$mqtt.connected) {
      // 连接正常
      console.log(" /连接正常-/ ");
    } else {
      // 连接断开
      console.log(" /连接断开-/");

      _this.$mqtt.reconnect();
    }
  },
  BGDark() {
    document.documentElement.style.setProperty(
      '--theme_bg_color',
      '#002965'
    )
    document.documentElement.style.setProperty(
      '--theme_font_color',
      '#ffffff'
    )
    document.documentElement.style.setProperty(
      '--theme_active_color',
      '#23aaea'
    )
    document.documentElement.style.setProperty(
      '--theme_submenu_color',
      '#043180'
    )
    document.documentElement.style.setProperty(
      '--theme_tagName_color',
      '#1d4c9e'
    )
    document.documentElement.style.setProperty(
      '--theme_tagName_font_color',
      '#a7bbdc'
    )
    document.documentElement.style.setProperty(
      '--theme_tagName_hover_color',
      '#23aaea'
    )
    document.documentElement.style.setProperty(
      '--theme_font_left_color',
      '#ffffff'
    )
    document.documentElement.style.setProperty(
      '--theme_bg_content_color',
      '#06193a'
    )
    document.documentElement.style.setProperty(
      '--theme_bg_tab_color',
      '#06193a'
    )
    document.documentElement.style.setProperty(
      '--theme_bg_left_color',
      '#043180'
    )
  },
  BGbright() {
    sessionStorage.setItem('currentSkin', 'bright')
    document.documentElement.style.setProperty(
      '--theme_bg_color',
      '#ffffff'
    )
    document.documentElement.style.setProperty(
      '--theme_font_color',
      '#000000'
    )
    document.documentElement.style.setProperty(
      '--theme_active_color',
      '#23aaea'
    )
    document.documentElement.style.setProperty(
      '--theme_submenu_color',
      '#1d4c9e'
    )
    document.documentElement.style.setProperty(
      '--theme_tagName_color',
      '#c7cfde'
    )
    document.documentElement.style.setProperty(
      '--theme_tagName_font_color',
      '#ffffff'
    )
    document.documentElement.style.setProperty(
      '--theme_tagName_hover_color',
      '#23aaea'
    )
    document.documentElement.style.setProperty(
      '--theme_font_left_color',
      '#ffffff'
    )
    document.documentElement.style.setProperty(
      '--theme_bg_content_color',
      '#f0f3f8'
    )
    document.documentElement.style.setProperty(
      '--theme_bg_tab_color',
      '#e2e7f1'
    )
    document.documentElement.style.setProperty(
      '--theme_bg_left_color',
      '#1d4c9e'
    )
  },
    // 导出xlsx
    downloadFile(file, fileName) {
      const blob = new Blob([file])
      let tag = document.createElement('a')
      tag.download = `${fileName}.xlsx`
      tag.style.display = 'none'
      tag.href = URL.createObjectURL(blob)
      document.body.appendChild(tag)
      tag.click()
      URL.revokeObjectURL(tag.href)
      document.body.removeChild(tag)
  },

};
