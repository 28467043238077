import axios from "axios";
import qs from "qs";
// import baseUrl from './config.js'
import baseURL from "./env.js";

axios.defaults.timeout = 15000;
axios.defaults.baseURL = baseURL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

//http request 拦截器
axios.interceptors.request.use(
  function(config) {
    if (config.method === "get") {
      config.paramsSerializer = function(params) {
        return qs.stringify(params, {
          arrayFormat: "repeat",
        });
      };
    }

    
    if (sessionStorage.getItem("grant") === "out") {
      var v = window.document.cookie.match(
        "(^|;) ?" + "usertoken" + "=([^;]*)(;|$)"
      );

      if (v) {
        config.headers["Authorization"] = "bearer " + v[2];
        console.log("out", v[2]);
      } else {
        config.headers["Authorization"] = "Basic ZW5lcmd5OmVuZXJneTEyMw==";
      }
      config.headers["responseType"] = "multipart/form-data";
    } else if(sessionStorage.getItem("grant") === "in"){
      var v = window.document.cookie.match(
        "(^|;) ?" + "token" + "=([^;]*)(;|$)"
      );

      if (v) {
        config.headers["Authorization"] = "bearer " + v[2];
      } else {
        config.headers["Authorization"] = "Basic ZW5lcmd5OmVuZXJneTEyMw==";
      }
      config.headers["responseType"] = "multipart/form-data";
    }else{
        config.headers["Authorization"] = "Basic ZW5lcmd5OmVuZXJneTEyMw==";
      config.headers["responseType"] = "multipart/form-data";
    }




    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 *
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}


export function getType(url, data = {}, type = null) {
  return new Promise((resolve, reject) => {
      axios.get(url, {
        params: data,
       responseType: type }).then(response => {
          resolve(response.data);
      }, err => {
          reject(err)
      })
  })
}
