import url from './api.js'
import axios from 'axios'

export default function getToken(vm) {
  if (sessionStorage.getItem('grant') === 'out') {
    if (vm.common.get('userreToken')) {
      axios.defaults.headers['Authorization'] = 'Basic ZW5lcmd5OmVuZXJneTEyMw=='
      axios
        .get(
          url.LOGIN +
            '?grant_type=refresh_token' +
            '&refresh_token=' +
            vm.common.get('userreToken')
        )
        .then((res) => {
          vm.$store.dispatch('actionTempUser', true)
          if (res.status == 200) {
            vm.common.set('usertoken', res.data['access_token'])
            vm.common.set('userreToken', res.data['refresh_token'])
            vm.common.set('userexpiresin', res.data['expires_in'])
          }
          console.log(
            vm.common,
            '----------------------------------vm.commonvm.commonvm.commonvm.commonvm.common'
          )
          vm.$Message.warning('token失效正在为你重新获取token!')
          vm.$router.go(0)
        })
        .catch(() => {
          vm.$Message.warning('登录超时，请重新登录！')
          if (sessionStorage.getItem('grant') === 'out') {
            vm.$router.push({
              path: '/',
            })
          } else {
            vm.$router.push({
              path: '/admin',
            })
          }
          vm.common.delete('usertoken')
          vm.common.delete('userreToken')
          vm.common.delete('userexpiresin')
          sessionStorage.removeItem('StationName')
          sessionStorage.removeItem('StationId')
        })
    }
  } else {
    if (vm.common.get('reToken')) {
      axios.defaults.headers['Authorization'] = 'Basic ZW5lcmd5OmVuZXJneTEyMw=='
      axios
        .get(
          url.LOGIN +
            '?grant_type=refresh_token' +
            '&refresh_token=' +
            vm.common.get('reToken')
        )
        .then((res) => {
          vm.$store.dispatch('actionTemp', true)
          if (res.status == 200) {
            vm.common.set('token', res.data['access_token'])
            vm.common.set('reToken', res.data['refresh_token'])
            vm.common.set('expiresin', res.data['expires_in'])
          }
          console.log(
            vm.common,
            '----------------------------------vm.commonvm.commonvm.commonvm.commonvm.common'
          )
          vm.$Message.warning('token失效正在为你重新获取token!')
          vm.$router.go(0)
        })
        .catch(() => {
          vm.$Message.warning('登录超时，请重新登录！')
          if (sessionStorage.getItem('grant') === 'out') {
            vm.$router.push({
              path: '/',
            })
          } else {
            vm.$router.push({
              path: '/admin',
            })
          }
          vm.common.delete('token')
          vm.common.delete('reToken')
          vm.common.delete('expiresin')
          sessionStorage.removeItem('StationName')
          sessionStorage.removeItem('StationId')
        })
    }
  }
  
}
