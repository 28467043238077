const URL = {
  LOGIN: "/energy/oauth/token", //登录

  USERINSIDE_LEFT_MENU: "/energy/sys/permission/userInside/leftMenu", //获取内部用户左侧菜单
  USEROUTSIDE_LEFT_MENU: "/energy/sys/permission/userOutside/leftMenu", //获取外部用户左侧菜单

  
  CTRL_REMOTERECORD_doSet4Tag: "/energy/ctrl/remoteRecord/doSet4Tag", //远程控制图纸
  //    基础管理_分中心信息表
  BASE_SUBCENTER_ADD: "/energy/base/subCenter/add", //分中心信息表添加数据
  BASE_SUBCENTER_GETPAGE: "/energy/base/subCenter/getPage", //分中心信息表分页查询
  BASE_SUBCENTER_MODIFY: "/energy/base/subCenter/modify", //分中心信息表修改
  BASE_SUBCENTER_DELETE: "/energy/base/subCenter/delete", //分中心信息表删除
  BASE_SUBCENTER_GETLIST: "/energy/base/subCenter/getList", //分中心列表
  BASE_SUBCENTER_GETTREE: "/energy/base/subCenter/getTree", //分中心树
  BASE_SUBCENTER_SWITCHACTIVE: "/energy/base/subCenter/switchActive", //状态变更

  //    基础管理_企业信息表
  BASE_COMPANY_ADD: "/energy/base/company/add", //企业信息表添加
  BASE_COMPANY_DELETE: "/energy/base/company/delete", //企业信息表删除
  BASE_COMPANY_GETLIST: "/energy/base/company/getList", //企业信息表下拉列表
  BASE_COMPANY_GETPAGE: "/energy/base/company/getPage", //企业信息表分页查询
  BASE_COMPANY_MODIFY: "/energy/base/company/modify", //企业信息表修改信息
  BASE_COMPANY_ADD: "/energy/base/company/add", //企业信息表添加
  BASE_INDUSTRY_GETLIST: "/energy/base/industry/getList", //行业下拉

  //    基础管理_企业通讯录
  BASE_CONTACT_ADD: "/energy/base/companyContact/add", //企业通讯录表添加
  BASE_CONTACT_DELETE: "/energy/base/companyContact/delete", //企业通讯录表删除
  BASE_CONTACT_GETPAGE: "/energy/base/companyContact/getPage", //企业通讯录表分页查询
  BASE_CONTACT_MODIFY: "/energy/base/companyContact/modify", //企业通讯录表修改

  //    基础管理_电站信息表
  BASE_STATION_ADD: "/energy/base/station/add", //电站信息表添加
  BASE_STATION_DELETE: "/energy/base/station/delete", //电站信息表删除
  BASE_STATION_GETLIST: "/energy/base/station/getList", //电站列表
  BASE_STATION_GETGRANTLIST: "/energy/base/station/getGrantList", // 获取授权电站列表
  BASE_STATION_GETPAGE: "/energy/base/station/getPage", //电站信息表分页查询
  BASE_STATION_MODIFY: "/energy/base/station/modify", //电站信息表修改
  BASE_STATION_SWITCHACTIVE: "/energy/base/station/switchActive", //状态变更
  BASE_STATION_GETMAPBYPOINT: "/energy/base/station/getMapByPoint", //根据点位查找电站信息
  BASE_STATION_REFRESHMAPCACHE: "/energy/base/station/refreshMapCache", //刷新地图电站缓存

  //    基础管理_通讯信息表
  BASE_DEVICE_ADD: "/energy/base/device/add", //通讯信息表添加
  BASE_DEVICE_DELETE: "/energy/base/device/delete", //通讯信息表删除
  BASE_DEVICE_GETLIST: "/energy/base/device/getList", //通讯信息表通讯下拉
  BASE_DEVICE_GETPAGE: "/energy/base/device/getPage", //通讯信息表分页查询
  BASE_DEVICE_MODIFY: "/energy/base/device/modify", //通讯信息表修改

  //    基础管理_区域信息表
  BASE_AREA_ADD: "/energy/base/area/add", //区域信息表添加dianz
  BASE_AREA_DELETE: "/energy/base/area/delete", //区域信息表删除
  BASE_AREA_GETLIST: "/energy/base/area/getList", //区域信息表下拉列表
  BASE_AREA_GETPAGE: "/energy/base/area/getPage", //区域信息表分页查询
  BASE_AREA_MODIFY: "/energy/base/area/modify", //区域信息表修改信息

  //    基础管理_设备信息表
  BASE_SEEPOINT_ADD: "/energy/base/seepoint/add", //设备信息表添加
  BASE_SEEPOINT_DELETE: "/energy/base/seepoint/delete", //设备信息表删除
  BASE_SEEPOINT_GETLIST: "/energy/base/seepoint/getList", //设备信息表设备下拉
  BASE_SEEPOINT_GETPAGE: "/energy/base/seepoint/getPage", //设备信息表分页查询
  BASE_SEEPOINT_MODIFY: "/energy/base/seepoint/modify", //设备信息表修改信息
  BASE_SEEPOINT_GETSEEPOINTTREE: "/energy/base/seepoint/getSeepointTree", //设备信息树状图

  //    基础管理_测点信息表
  BASE_DATAPOINT_ADD: "/energy/base/datapoint/add", //测点信息表添加
  BASE_DATAPOINT_DELETE: "/energy/base/datapoint/delete", //测点信息表删除
  BASE_DATAPOINT_GETPAGE: "/energy/base/datapoint/getPage", //测点信息表分页查询
  BASE_DATAPOINT_MODIFY: "/energy/base/datapoint/modify", //测点信息表修改信息
  BASE_DATAPOINT_DETAIL: "/energy/base/datapoint/detail", //测点信息表 获取详细信息
  BASE_DATAPOINT_GETLIST: "/energy/base/datapoint/getList", //测点列表
  BASE_DATAPOINT_GETINTERSECTIONLIST:
    "/energy/base/datapoint/getIntersectionList", //测点列表

  //    基础管理_测点类型信息表
  BASE_DATATYPE_GETLIST: "/energy/base/dataType/getList", //测点类型下拉列表
  BASE_DATATYPE_DATAIL: "/energy/base/dataType/detail", //获取详细信息

  //    基础管理_数据导入
  BASE_IMPORT_EXCEL: "/energy/base/import/excel",

  //    基础管理_数据导入日志
  BASE_IMPORTLOG_CLEAR: "/energy/base/importLog/clear", // 清楚导入数据
  BASE_IMPORTLOG_GETPAGE: "/energy/base/importLog/getPage", // 分页查询

  //    基础管理_数据查询
  BASE_HDATA_REALTIMEDATA: "/energy/base/hdata/getRealTimeLastDataBySeepoint", //实时数据查询
  BASE_HDATA_HISTORYDATA: "/energy/base/hdata/getHistoryData", //历史数据查询
  BASE_HDATA_EQIOPMENTDATA: "/energy/base/hdata/getEquipmentComparisonData", //设备对比查询
  BASE_HDATA_TIMEDATA: "/energy/base/hdata/getTimeComparisonData", //时间对比查询

  // 运维管理_值班班次—分中心值班班次信息表
  OP_DUTYSHIFTSUBCENTER_ADD: "/energy/op/dutyShiftSubCenter/add", //添加
  OP_DUTYSHIFTSUBCENTER_DELETE: "/energy/op/dutyShiftSubCenter/delete", //删除
  OP_DUTYSHIFTSUBCENTER_GETLIST: "/energy/op/dutyShiftSubCenter/getList", //班次列表
  OP_DUTYSHIFTSUBCENTER_GETPAGE: "/energy/op/dutyShiftSubCenter/getPage", //分页查询
  OP_DUTYSHIFTSUBCENTER_MODIFY: "/energy/op/dutyShiftSubCenter/modify", //修改信息

  // 运维管理_值班班次—电站值班班次信息表
  OP_DUTYSHIFTSTATION_ADD: "/energy/op/dutyShiftStation/add", //添加
  OP_DUTYSHIFTSTATION_DELETE: "/energy/op/dutyShiftStation/delete", //删除
  OP_DUTYSHIFTSTATION_GETLIST: "/energy/op/dutyShiftStation/getList", //班次列表
  OP_DUTYSHIFTSTATION_GETPAGE: "/energy/op/dutyShiftStation/getPage", //分页查询
  OP_DUTYSHIFTSTATION_MODIFY: "/energy/op/dutyShiftStation/modify", //修改信息

  // 运维管理_值班计划—分中心值班计划信息表
  OP_DUTYPLANSUBCENTER_ADD: "/energy/op/dutyPlanSubCenter/add", //添加
  OP_DUTYPLANSUBCENTER_DELETE: "/energy/op/dutyPlanSubCenter/delete", //删除
  OP_DUTYPLANSUBCENTER_GETLIST: "/energy/op/dutyPlanSubCenter/getList", //当月值班计划列表
  OP_DUTYPLANSUBCENTER_GETPAGE: "/energy/op/dutyPlanSubCenter/getPage", //分页查询
  OP_DUTYPLANSUBCENTER_MODIFY: "/energy/op/dutyPlanSubCenter/modify", //修改信息

  // 运维管理_值班计划—电站值班计划信息表     dutyPlanStation
  OP_DUTYPLANSTATION_ADD: "/energy/op/dutyPlanStation/add", //添加
  OP_DUTYPLANSTATION_DELETE: "/energy/op/dutyPlanStation/delete", //删除
  OP_DUTYPLANSTATION_GETLIST: "/energy/op/dutyPlanStation/getList", //当月值班计划列表
  OP_DUTYPLANSTATION_GETPAGE: "/energy/op/dutyPlanStation/getPage", //分页查询
  OP_DUTYPLANSTATION_MODIFY: "/energy/op/dutyPlanStation/modify", //修改信息

  //值班人员 巡检人员  系统管理_内部用户信息表_内部用户列表
  OP_USERINSIDE_GETLIST: "/energy/sys/userInside/getList",

  // 运维管理 巡检模板信息表
  OP_PATROLTEMPLATE_ADD: "/energy/op/patrolTemplate/add", //添加
  OP_PATROLTEMPLATE_DELETE: "/energy/op/patrolTemplate/delete", //删除
  OP_PATROLTEMPLATE_GETPAGE: "/energy/op/patrolTemplate/getPage", //分页查询
  OP_PATROLTEMPLATE_MODIFY: "/energy/op/patrolTemplate/modify", //修改信息
  OP_PATROLTEMPLATE_GETLIST: "/energy/op/patrolTemplate/getList", //巡检模板列表

  // 运维管理 巡检项目信息表
  OP_PATROLITEM_ADD: "/energy/op/patrolItem/add", //添加
  OP_PATROLITEM_DELETE: "/energy/op/patrolItem/delete", //删除
  OP_PATROLITEM_GETPAGE: "/energy/op/patrolItem/getPage", //分页查询
  OP_PATROLITEM_MODIFY: "/energy/op/patrolItem/modify", //修改信息

  //  分中心 导出
  OP_DUTYPLANSUBCENTER_EXPORT: "/energy/op/dutyPlanSubCenter/export", //导出
  //  电站 导出
  OP_DUTYPLANSTATION_EXPORT: "/energy/op/dutyPlanStation/export", //导出

  // 运维管理 巡检工单信息表
  OP_WORKORDERPATROL_ACCEPT: "/energy/op/workOrderPatrol/accept", // 接受
  OP_WORKORDERPATROL_COMPLETE: "/energy/op/workOrderPatrol/complete", //完成
  OP_WORKORDERPATROL_DETAIL: "/energy/op/workOrderPatrol/detail", //获取详细信息
  OP_WORKORDERPATROL_GETPAGE: "/energy/op/workOrderPatrol/getPage", //分页查询
  OP_WORKORDERPATROL_MODIFY: "/energy/op/workOrderPatrol/modify", //修改信息

  // 运维管理 保养工单信息表
  OP_WORKORDERMAINTAIN_ACCEPT: "/energy/op/workOrderMaintain/accept", // 接受
  OP_WORKORDERMAINTAIN_COMPLETE: "/energy/op/workOrderMaintain/complete", //完成
  OP_WORKORDERMAINTAIN_DETAIL: "/energy/op/workOrderMaintain/detail", //获取详细信息
  OP_WORKORDERMAINTAIN_GETPAGE: "/energy/op/workOrderMaintain/getPage", //分页查询
  OP_WORKORDERMAINTAIN_MODIFY: "/energy/op/workOrderMaintain/modify", //修改信息

  // 运维管理 维修工单信息表
  OP_WORKORDERREPAIR_ACCEPT: "/energy/op/workOrderRepair/accept", // 接受
  OP_WORKORDERREPAIR_ASSIGN: "/energy/op/workOrderRepair/assign", // 指派
  OP_WORKORDERREPAIR_COMPLETE: "/energy/op/workOrderRepair/complete", //完成
  OP_WORKORDERREPAIR_DETAIL: "/energy/op/workOrderRepair/detail", //获取详细信息
  OP_WORKORDERREPAIR_GETPAGE: "/energy/op/workOrderRepair/getPage", //分页查询
  OP_WORKORDERREPAIR_MODIFY: "/energy/op/workOrderRepair/modify", //修改信息

  // 运维管理 流程记录信息表
  OP_WORKFLOWRECORD_SUBMIT: "/energy/op/workflowRecord/submit", // 提交
  OP_WORKFLOWRECORD_EXAMINE: "/energy/op/workflowRecord/examine", // 审核
  OP_WORKFLOWRECORD_GETLIST: "/energy/op/workflowRecord/getList", // 获取审核列表

  // 海康视频
  HIKVISION_VIDEO_GETCAMERAS: "/energy/hikvision/video/getCameras", // 根据区域编号获取下级监控点列表
  HIKVISION_VIDEO_GETCONTROLLING: "/energy/hikvision/video/getControlling", // 根据监控点编号进行云台操作
  HIKVISION_VIDEO_GETNODETREE: "/energy/hikvision/video/getNodeTree", // 查询区域列表
  HIKVISION_VIDEO_GETPLAYBACKURLS: "/energy/hikvision/video/getPlaybackURLs", // 获取监控点回放取流URL
  HIKVISION_VIDEO_GETPREVIEWURLS: "/energy/hikvision/video/getPreviewURLs", // 获取监控点预览取流URL
  HIKVISION_VIDEO_GETSEARCHES: "/energy/hikvision/video/getSearches", // 查询预置点信息

  // 操作票信息表
  OP_OPERATETICKET_GETLIST: "/energy/op/operateTicket/getList", // 操作票列表

  // 系统管理_字典值域信息表
  SYS_DICTDATA_GETDICTDATA: "/energy/sys/dictData/getDictData", //企业通讯中的报警推送下拉
  SYS_ROLEINSIDE_ADD: "/energy/sys/roleInside/add", //新增内部角色
  SYS_ROLEINSIDE_GETLIST: "/energy/sys/roleInside/getList", //内部角色下拉
  SYS_ROLEINSIDE_DELETE: "/energy/sys/roleInside/delete", //删除内部角色
  SYS_ROLEINSIDE_BATCHDELETE: "/energy/sys/roleInside/batchDelete", //批量删除内部角色
  SYS_ROLEINSIDE_DETAIL: "/energy/sys/roleInside/detail", //内部角色详情
  SYS_ROLEINSIDE_GETPAGE: "/energy/sys/roleInside/getPage", //分页获取内部角色列表
  SYS_ROLEINSIDE_MODIFY: "/energy/sys/roleInside/modify", //修改内部角色
  SYS_PERMISSION_GETPERMISSION: "/energy/sys/permission/getPermission", //获取权限列表

  SYS_USERINSIDE_ADD: "/energy/sys/userInside/add", //新增内部用户信息
  SYS_USERINSIDE_DELETE: "/energy/sys/userInside/delete", //内部用户信息删除
  SYS_USERINSIDE_BATCHDELETE: "/energy/sys/userInside/batchDelete", //批量删除内部用户
  SYS_USERINSIDE_GETPAGE: "/energy/sys/userInside/getPage", //内部用户信息列表
  SYS_USERINSIDE_MODIFY: "/energy/sys/userInside/modify", //内部用户信息修改
  SYS_DEPT_GETLIST: "/energy/sys/dept/getList", //部门下拉列表
  SYS_DEPT_GETPAGE: "/energy/sys/dept/getPage", //部门分页查询
  SYS_DEPT_MODIFY: "/energy/sys/dept/modify", //修改部门信息
  SYS_DEPT_DELETE: "/energy/sys/dept/delete", //部门信息删除
  SYS_DEPT_BATCHDELETE: "/energy/sys/dept/batchDelete", // 批量删除部门
  SYS_DEPT_ADD: "/energy/sys/dept/add", //部门信息添加
  SYS_DEPT_GETDEPTTREE: "/energy/sys/dept/getDeptTree", //部门信息树

  SYS_POST_ADD: "/energy/sys/post/add", //添加职务信息
  SYS_POST_DELETE: "/energy/sys/post/delete", //删除职务信息
  SYS_POST_GETPAGE: "/energy/sys/post/getPage", //分页查询职务信息
  SYS_POST_MODIFY: "/energy/sys/post/modify", //修改职务信息
  SYS_POST_GETLIST: "/energy/sys/post/getList", //职务下拉列表
  SYS_POST_BATCHDELETE: "/energy/sys/post/batchDelete", // 批量删除职务

  SYS_USERINSIDE_GETCURRENT: "/energy/sys/userInside/getCurrent", //获取当前登录用户信息
  SYS_USERINSIDE_MODIFYPASSWORD: "/energy/sys/userInside/modifyPassword", //修改密码
  SYS_USERINSIDE_RESETPASSWORD: "/energy/sys/userInside/resetPassword", //内部用户重置密码
  SYS_USEROUTSIDE_RESETPASSWORD: "/energy/sys/userOutside/resetPassword", //外部用户重置密码

  SYS_USEROUTSIDE_ADD: "/energy/sys/userOutside/add", //添加外部用户信息
  SYS_USEROUTSIDE_DELETE: "/energy/sys/userOutside/delete", //删除外部用户信息
  SYS_USEROUTSIDE_GETPAGE: "/energy/sys/userOutside/getPage", //分页外部用户查询
  SYS_USEROUTSIDE_GETPAGE: "/energy/sys/userOutside/getPage", //分页外部用户查询
  SYS_USEROUTSIDE_MODIFY: "/energy/sys/userOutside/modify", //修改外部用户信息
  SYS_USEROUTSIDE_BATCHDELETE: "/energy/sys/userOutside/batchDelete", //批量删除外部用户
  SYS_USEROUTSIDE_GETCURRENT: "/energy/sys/userOutside/getCurrent", //获取当前登录用户信息
  SYS_USEROUTSIDE_MODIFYPASSWORD: "/energy/sys/userOutside/modifyPassword", //修改外部用户密码

  SYS_ROLEOUTSIDE_ADD: "/energy/sys/roleOutside/add", //添加外部角色信息
  SYS_ROLEOUTSIDE_DELETE: "/energy/sys/roleOutside/delete", //删除外部角色信息
  SYS_ROLEOUTSIDE_BATCHDELETE: "/energy/sys/roleOutside/batchDelete", // 批量删除外部角色
  SYS_ROLEOUTSIDE_GETPAGE: "/energy/sys/roleOutside/getPage", //分页查询外部角色信息
  SYS_ROLEOUTSIDE_MODIFY: "/energy/sys/roleOutside/modify", //修改外部角色信息
  SYS_ROLEOUTSIDE_DETAIL: "/energy/sys/roleOutside/detail", //外部角色详情
  SYS_ROLEOUTSIDE_GETLIST: "/energy/sys/roleOutside/getList", //外部角色下拉

  OPERATION_OUTAGEPLAN_ADD: "/energy/op/outagePlan/add", //添加停电计划信息
  OPERATION_OUTAGEPLAN_DELETE: "/energy/op/outagePlan/delete", //删除停电计划信息
  OPERATION_OUTAGEPLAN_DETAIL: "/energy/op/outagePlan/detail", //获取停电计划详细信息
  OPERATION_OUTAGEPLAN_MODIFY: "/energy/op/outagePlan/modify", //修改信息停电计划信息
  OPERATION_OUTAGEPLAN_GETPAGE: "/energy/op/outagePlan/getPage", //分页查询停电计划信息

  OPERATION_OPERATETICKET_ADD: "/energy/op/operateTicket/add", //添加操作票信息
  OPERATION_OPERATETICKET_DELETE: "/energy/op/operateTicket/delete", //删除操作票信息
  OPERATION_OPERATETICKET_DETAIL: "/energy/op/operateTicket/detail", //获取操作票详细信息
  OPERATION_OPERATETICKET_MODIFY: "/energy/op/operateTicket/modify", //修改操作票信息
  OPERATION_OPERATETICKET_GETPAGE: "/energy/op/operateTicket/getPage", //分页查询操作票信息

  OPERATION_MAINTAINPLAN_ADD: "/energy/op/maintainPlan/add", //添加保养计划信息
  OPERATION_MAINTAINPLAN_DELETE: "/energy/op/maintainPlan/delete", //删除保养计划信息
  OPERATION_MAINTAINPLAN_DETAIL: "/energy/op/maintainPlan/detail", //获取保养计划详细信息
  OPERATION_MAINTAINPLAN_MODIFY: "/energy/op/maintainPlan/modify", //修改保养计划信息
  OPERATION_MAINTAINPLAN_GETPAGE: "/energy/op/maintainPlan/getPage", //分页查询保养计划信息

  OPERATION_MAINTAINPROJECT_ADD: "/energy/op/maintainProject/add", //添加保养项目信息
  OPERATION_MAINTAINPROJECT_GETLIST: "/energy/op/maintainProject/getList", //保养项目列表
  OPERATION_MAINTAINPROJECT_DELETE: "/energy/op/maintainProject/delete", //删除保养项目信息
  OPERATION_MAINTAINPROJECT_DETAIL: "/energy/op/maintainProject/detail", //获取保养项目详细信息
  OPERATION_MAINTAINPROJECT_MODIFY: "/energy/op/maintainProject/modify", //修改保养项目信息
  OPERATION_MAINTAINPROJECT_GETPAGE: "/energy/op/maintainProject/getPage", //分页查询保养项目信息

  OPERATION_FILE_IMGSUPLOAD: "/energy/op/file/imgsUpload", //批量图片上传
  OPERATION_FILE_DELETE: "/energy/op/file/delete", //删除图片

  OPERATION_PATROLPLAN_ADD: "/energy/op/patrolPlan/add", //添加巡检计划信息
  OPERATION_PATROLPLAN_DELETE: "/energy/op/patrolPlan/delete", //删除巡检计划信息
  OPERATION_PATROLPLAN_DETAIL: "/energy/op/patrolPlan/detail", //获取巡检计划详细信息
  OPERATION_PATROLPLAN_MODIFY: "/energy/op/patrolPlan/modify", //修改巡检计划信息
  OPERATION_PATROLPLAN_GETPAGE: "/energy/op/patrolPlan/getPage", //分页查询巡检计划信息

  OPERATION_OUTAGEPLAN_ADD: "/energy/op/outagePlan/add", //添加停电计划信息
  OPERATION_OUTAGEPLAN_DELETE: "/energy/op/outagePlan/delete", //删除停电计划信息
  OPERATION_OUTAGEPLAN_DETAIL: "/energy/op/outagePlan/detail", //获取停电计划详细信息
  OPERATION_OUTAGEPLAN_MODIFY: "/energy/op/outagePlan/modify", //修改信息停电计划信息
  OPERATION_OUTAGEPLAN_GETPAGE: "/energy/op/outagePlan/getPage", //分页查询停电计划信息

  OPERATION_MAINTAINPLAN_ADD: "/energy/op/maintainPlan/add", //添加保养计划信息
  OPERATION_MAINTAINPLAN_DELETE: "/energy/op/maintainPlan/delete", //删除保养计划信息
  OPERATION_MAINTAINPLAN_DETAIL: "/energy/op/maintainPlan/detail", //获取保养计划详细信息
  OPERATION_MAINTAINPLAN_MODIFY: "/energy/op/maintainPlan/modify", //修改保养计划信息
  OPERATION_MAINTAINPLAN_GETPAGE: "/energy/op/maintainPlan/getPage", //分页查询保养计划信息

  OPERATION_MAINTAINPROJECT_ADD: "/energy/op/maintainProject/add", //添加保养项目信息
  OPERATION_MAINTAINPROJECT_DELETE: "/energy/op/maintainProject/delete", //删除保养项目信息
  OPERATION_MAINTAINPROJECT_DETAIL: "/energy/op/maintainProject/detail", //获取保养项目详细信息
  OPERATION_MAINTAINPROJECT_MODIFY: "/energy/op/maintainProject/modify", //修改保养项目信息
  OPERATION_MAINTAINPROJECT_GETPAGE: "/energy/op/maintainProject/getPage", //分页查询保养项目信息

  // 字典类型
  DICTTYPE_ADD: "/energy/sys/dictType/add", // 新增字典类型
  DICTTYPE_DEL: "/energy/sys/dictType/delete", // 删除字典类型
  DICTTYPE_MODIFY: "/energy/sys/dictType/modify", // 修改字典类型
  DICTTYPE_DETAIL: "/energy/sys/dictType/detail", // 字典类型详细信息
  DICTTYPE_GETPAGE: "/energy/sys/dictType/getPage", // 字典类型分页查询
  // 值域管理
  DICTDATA_ADD: "/energy/sys/dictData/add", // 新增字典值域
  DICTDATA_DEL: "/energy/sys/dictData/delete", // 删除
  DICTDATA_MODIFY: "/energy/sys/dictData/modify", // 修改
  DICTDATA_DETAIL: "/energy/sys/dictData/detail", // 详细信息
  DICTDATA_GETPAGE: "/energy/sys/dictData/getPage", // 分页查询
  GET_DICTDATA: "/energy/sys/dictData/getDictData", // 获取字典值域信息

  //电站信息表
  ENERGY_BASE_DRAWING_GETLIST: "/energy/base/drawing/getList", // 图纸列表
  ENERGY_BASE_DRAWING_DETAIL: "/energy/base/drawing/detail", // 图纸详情
  ENERGY_BASE_DRAWING_GETPAGE: "/energy/base/drawing/getPage", // 图纸分页
  //数据分析-根据图纸获取实时最新数据
  ENERGY_BASE_HDATA_GETREALTIMELASTDATABYDRAWING:
    "/energy/base/hdata/getRealTimeLastDataByDrawing", // 图纸列表
  //告警信息表
  ENERGY_AM_ALARMRECORD_CUMULATIVENUMBER:
    "/energy/am/alarmRecord/cumulativeNumber", // 累计报警次数
  ENERGY_AM_ALARMRECORD_GETLASTLIST: "/energy/am/alarmRecord/getLastList", // 最近20条报警数据
  ENERGY_AM_ALARMRECORD_GETPAGE: "/energy/am/alarmRecord/getPage", // 分页
  ENERGY_AM_ALARMRECORD_ASSIGNWORKORDER:
    "/energy/am/alarmRecord/assignWorkOrder", // 指派工单
  ENERGY_AM_ALARMRECORD_HANDLE: "/energy/am/alarmRecord/handle", // 报警处理
  ENERGY_AM_ALARMRECORD_BATCHHANDLE: "/energy/am/alarmRecord/batchHandle", // 批量报警处理
  ENERGY_AM_ALARMRECORD_GETHISTORYDATA: "/energy/am/alarmRecord/getHistoryData", // 历史数据查询
  ENERGY_AM_ALARMRECORD_GETHISTORYPAGEDATA:
    "/energy/am/alarmRecord/getHistoryPageData", // 历史数据分页查询
  ENERGY_AM_ALARMRECORD_GETHISTORYPAGEDATA_CLIENT:
    "/energy/am/alarmRecord/getHistoryAlarmPageData", // 客户端历史数据分页查询

  ENERGY_SYS_USEROUTSIDE_GRANTSTATION: "/energy/sys/userOutside/grantStation", //外部用户电站授权

  // 设备控制
  ENERGY_CTRL_REMOTERECORD_DOQUERY: "/energy/ctrl/remoteRecord/doQuery", //远程查询
  ENERGY_CTRL_REMOTERECORD_DOSET: "/energy/ctrl/remoteRecord/doSet", //远程设置

  // 系统管理 菜单信息表
  SYS_PERMISSION_ADD: "/energy/sys/permission/add", // 新增
  SYS_PERMISSION_DEL: "/energy/sys/permission/delete", // 删除
  SYS_PERMISSION_MODIFY: "/energy/sys/permission/modify", // 修改
  SYS_PERMISSION_GETPERMISSION: "/energy/sys/permission/getPermission", // 树

  //用电分析
  ENERYGY_ANALYSIS_GETREPORT: "/energy/analysis/energy/getReport", //用量报表
  ENERYGY_ANALYSIS_GETSAMECOMPARE: "/energy/analysis/energy/getSameCompare", //同比分析
  ENERYGY_ANALYSIS_GETHOOPCOMPARE: "/energy/analysis/energy/getHoopCompare", //环比分析
  ENERYGY_ANALYSIS_GETMETERREADING: "/energy/analysis/energy/getMeterReading", //电能集抄
  ENERYGY_ANALYSIS_GETENERGYREPORT: "/energy/analysis/energy/getEnergyReport", //能效报表
  ENERYGY_ANALYSIS_GETSTATIONRUNREPORT: "/energy/analysis/energy/getStationRunReport", //配电站运行月报

  //负荷分析
  ENERYGY_ANALYSIS_GETLOADRATE: "/energy/analysis/load/getLoadRate", //负荷率
  ENERYGY_ANALYSIS_GETLOADREPORT: "/energy/analysis/load/getLoadReport", //负荷报表
  //报警查询
  ENERYGY_BASE_GETPUSHLISTBYSTATION: "/energy/base/companyContact/getPushListByStation", //根据电站获取可以推送企业通讯录列表
  ENERYGY_AM_GETPUSHCONTENT: "/energy/am/alarmRecord/getPushContent", //获取推送内容
  ENERYGY_AM_PUSH: "/energy/am/alarmRecord/push", //推送
  ENERYGY_AM_GETPAGE: "/energy/am/alarmPushRecord/getPage", //推送记录
  //保存电站监控设备信息
  ENERYGY_BASE_SAVESTATIONVIDEO: "/energy/base/station/saveStationVideo", //保存电站监控设备信息
  ENERYGY_BASE_GETSTATIONVIDEO: "/energy/base/station/getStationVideo", //获取电站已关联监控设备列表
  //C端首页
  ENERYGY_ANALYSIS_ENERGY_GETSTATIONSTAT: "/energy/analysis/energy/getStationStat",
  ENERYGY_BASE_HDATA_GETSTATIONLOADDATA: "/energy/base/hdata/getStationLoadData",
  ENERYGY_BASE_HDATA_GETSTATIONELECTRICITYDATA: "/energy/base/hdata/getStationElectricityData",
  // 根据图纸获取测点列表
  ENERYGY_BASE_DATAPOINT_GETLISTBYDRAWINGID: "/energy/base/datapoint/getListByDrawingId",
  // 新能源首页
  ENERYGY_ANALYSIS_ENERGY_GETNEWENERGYSTATIONSTAT: "/energy/analysis/energy/getNewEnergyStationStat",
  // -电站历史功率曲线数据
  ENERYGY_BASE_HDATA_GETSTATIONPOWERDATA: "/energy/base/hdata/getStationPowerData",
  // -电站历史功率曲线数据
  ENERYGY_BASE_HDATA_GETSTATIONPHOTOVOLTAICDATA: "/energy/base/hdata/getStationPhotovoltaicData",
  // 获取密钥
  ENERGY_SYS_USERINSIDE_GETKEY: "/energy/sys/userInside/getKey",
  // 远程设置前校验密码
  ENERGY_CTRL_REMOTERECORD_CHECKPWD: "/energy/ctrl/remoteRecord/checkPwd",
  // 操作日志
  ENERGY_CTRL_REMOTERECORD_GETPAGE: "/energy/ctrl/remoteRecord/getPage",
};

module.exports = URL;
