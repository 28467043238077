import Vue from 'vue'
import Vuex from 'vuex'
import common from '@/utils/common.js'

Vue.use(Vuex)


function getStationIds() {
let myInfoData=JSON.parse(sessionStorage.getItem("myInfo"))
  if (myInfoData && myInfoData.stationIds ) {
    return myInfoData.stationIds.length>0?myInfoData.stationIds:['0'];
  }
}


const store = new Vuex.Store({
  nameSpace: true,
  state: {
    getToken: true,
    getuserToken: true,
    myInfo: {},
    leftList: [],
    isLogin: false,
    tabList: [],
    videoList: [],
    videoModel: 4,
    testkeepFlag: 0,
    tabList1: [],
    regionSelect: [], //区域下拉列表
    clientId: '',
    ALARMContentTotal: JSON.parse(localStorage.getItem("ALARMContentTotal")),
    ALARMContent: JSON.parse(sessionStorage.getItem("ALARMContent")),
    stationIds: getStationIds(),
    currentSkin: 'bright',
  },
  getters: {
    isLogin(state) {
      if (!state.isLogin) {
        state.isLogin = JSON.parse(sessionStorage.getItem('isLogin')) //从sessionStorage中读取状态
        state.myInfo = JSON.parse(sessionStorage.getItem('myInfo'))
        state.leftList = JSON.parse(sessionStorage.getItem('leftList'))
        state.tabList = JSON.parse(sessionStorage.getItem('tabList'))
        state.tabList1 = JSON.parse(sessionStorage.getItem('tabList1'))
      }
      return state.isLogin
    },
    currentSkin(state) {
      state.currentSkin = sessionStorage.getItem('currentSkin')

      return state.currentSkin
    },
  },
  mutations: {
    SET_Color(state, data) {
      // console.log(data, '----调用 45');
      state.currentSkin = Object.assign({}, data)
      sessionStorage.setItem('currentSkin', data)
    },

    SET_INFO(state, data) {
      state.myInfo = Object.assign({}, data)
      sessionStorage.setItem('myInfo', JSON.stringify(data))
      sessionStorage.setItem('isLogin', true)

      state.stationIds = getStationIds()

    },
    SET_LEFT(state, data) {
      state.leftList = Object.assign([], data)
      sessionStorage.setItem('leftList', JSON.stringify(data))
    },
    SET_TAB(state, data) {
      state.tabList = Object.assign([], data)
      sessionStorage.setItem('tabList', JSON.stringify(data))
    },
    SET_USERTAB(state, data) {
      state.tabList1 = Object.assign([], data)
      sessionStorage.setItem('tabList1', JSON.stringify(data))
    },
    GET_REGION(state, data) {
      state.regionSelect = Object.assign([], data)
    },
    CHANGE_TEMP(state, data) {
      state.getToken = data
    },
    CHANGE_TEMPUSER(state, data) {
      state.getuserToken = data
    },
    SIGN_OUT(state) {
      //退出，删除状态
      sessionStorage.clear()
      common.delete('usertoken')
      common.delete('token')
      state.myInfo = {}
      state.leftList = []
      state.tabList = []
      state.getToken = true
      state.getuserToken = true
      state.isLogin = false
      state.regionSelect = []
    },
    LOGIN_Refresh(state) {
      sessionStorage.clear()
      state.myInfo = {}
      state.leftList = []
      state.tabList = []
      state.getToken = true
      state.getuserToken = true
      state.isLogin = false
      state.regionSelect = []
    },
  },
  actions: {
    actionColor(context, data) {
      context.commit('SET_Color', data)
    },
    actionUser(context, data) {
      context.commit('SET_INFO', data)
    },
    actionMuen(context, data) {
      context.commit('SET_LEFT', data)
    },
    actionTab(context, data) {
      context.commit('SET_TAB', data)
    },
    actionTab1(context, data) {
      context.commit('SET_USERTAB', data)
    },
    actionTemp(context, data) {
      context.commit('CHANGE_TEMP', data)
    },
    actionTempUser(context, data) {
      context.commit('CHANGE_TEMPUSER', data)
    },
    actionOut(context) {
      context.commit('LOGIN_Refresh')
    },
    actionRegion(context, data) {
      if (context.state.regionSelect.length > 0) {
      } else {
        // data.$get(url.REGION_SELECT).then(res => {
        //     console.log(res, "---------- store res")
        //         // 通过 actions 执行 mutations 的方法
        //     context.commit('GET_REGION', res)
        // }).catch(error => {
        //     data.common.tokenIsTrue(data, error);
        // })
      }
    },
  },
})
export default store
