import Vue from "vue";
import Router from "vue-router";
import Login from "@/view/login";
import UserLogin from "@/view/userlogin";
import Pdz from "@/view/pdz";
import ThreeMap from "@/components/threeMap/threeMap";
import Theme from "@/view/theme";

Vue.use(Router);

const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

let router = new Router({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: "/admin",
      name: "login",
      component: Login,
    },
    {
      path: "/",
      name: "userLogin",
      component: UserLogin,
    },

    {
      path: "/theme",
      name: "theme",
      component: Theme,
    },
    {
      path: "/pdz",
      name: "pdz",
      component: Pdz,
    },
    {
      path: "/owner",
      name: "owner",
      component: (resolve) => require(["@/components/mainCom"], resolve),
      children: [
        {
          path: "/index",
          name: "index",
          component: () => import("@/view/index"),
        },
        {
          path: "/index1",
          name: "index1",
          component: () => import("@/view/owner/index1"),
        },
        {
          path: "/index2",
          name: "index2",
          component: () => import("@/view/owner/index2"),
        },

        {
          path: "/region",
          name: "region",
          component: () => import("@/view/base/region"),
        },
        {
          path: "/subCenter",
          name: "subCenter",
          component: () => import("@/view/base/subCenter"),
        },
        {
          path: "/realTimeVideo",
          name: "realTimeVideo",
          component: () => import("@/view/videoMonitor/realTimeVideo"),
        },
        {
          path: "/replay",
          name: "replay",
          component: () => import("@/view/videoMonitor/replay"),
        },
        {
          path: "/monthlyReport",
          name: "monthlyReport",
          component: () => import("@/view/runReport/monthlyReport"),
        },
        {
          path: "/testChart",
          name: "testChart",
          component: () => import("@/view/runReport/testChart"),
        },
        {
          path: "/electricDrawing",
          name: "electricDrawing",
          component: () =>
            import("@/view/owner/electricDrawing/electricDrawing"),
        },
        {
          path: "/inverter",
          name: "inverter",
          component: () => import("@/view/owner/deviceControl/inverter"),
        },
        {
          path: "/batteryPack",
          name: "batteryPack",
          component: () => import("@/view/owner/deviceControl/batteryPack"),
        },
        {
          path: "/circuitBreaker",
          name: "circuitBreaker",
          component: () => import("@/view/owner/deviceControl/circuitBreaker"),
        },
        {
          path: "/fan",
          name: "fan",
          component: () => import("@/view/owner/deviceControl/fan"),
        },
        {
          path: "/IndustrialAirC",
          name: "IndustrialAirC",
          component: () =>
            import("@/view/owner/deviceControl/IndustrialAirC"),
        },

        {
          path: "/pdz",
          name: "pdz",
          component: () => import("@/view/pdz"),
        },
        {
          path: "/time",
          name: "time",
          component: () => import("@/view/base/time"),
        },
        {
          path: "/temp",
          name: "temp",
          component: () => import("@/view/base/temp"),
        },
        {
          path: "/setTemp",
          name: "setTemp",
          component: () => import("@/view/base/setTemp"),
        },
        {
          path: "/building",
          name: "building",
          component: () => import("@/view/base/building"),
        },
        {
          path: "/plantManagement",
          name: "plantManagement",
          component: () => import("@/view/base/plantManagement"),
        },
        {
          path: "/historyCurrentWork",
          // name: 'historyCurrentWork',
          component: () => import("@/view/operation/historyCurrentWork"),
          children: [
            {
              path: "/",
              name: "historyCurrentWorkX",
              component: () => import("@/view/operation/historyCurrentWorkX"),
            },
            {
              path: "/historyCurrentWorkB",
              name: "historyCurrentWorkB",
              component: () => import("@/view/operation/historyCurrentWorkB"),
            },
            {
              path: "/historyCurrentWorkW",
              name: "historyCurrentWorkW",
              component: () => import("@/view/operation/historyCurrentWorkW"),
            },
          ],
        },
        {
          path: "/inspection",
          name: "inspection",
          component: () => import("@/view/operation/inspection"),
        },
        {
          path: "/patrolScheme",
          name: "patrolScheme",
          component: () => import("@/view/operation/patrolScheme"),
        },
        {
          path: "/currentAlarm",
          name: "currentAlarm",
          component: () => import("@/view/alarmquery/currentAlarm"),
        },
        {
          path: "/historicalAlarm1",
          name: "historicalAlarm1",
          component: () => import("@/view/alarmquery/historicalAlarm1"),
        },
        {
          path: "/pushRecord",
          name: "pushRecord",
          component: () => import("@/view/alarmquery/pushRecord"),
        },
        {
          path: "/projectS",
          // name: 'projectS',
          component: () => import("@/view/operation/projectS"),

          children: [
            {
              path: "/",
              name: "projectSub",
              component: () => import("@/view/operation/projectSub"),
            },
            {
              path: "/projectStation",
              name: "projectStation",
              component: () => import("@/view/operation/projectStation"),
            },
          ],
        },
        {
          path: "/shift",
          name: "shift",
          component: () => import("@/view/operation/shift"),
        },
        {
          path: "/comStatus",
          name: "comStatus",
          component: () => import("@/view/operation/comStatus"),
        },
        {
          path: "/currentWork",
          // name: 'currentWork',
          component: () => import("@/view/operation/currentWork"),

          children: [
            {
              path: "/",
              name: "currentWorkx",
              component: () => import("@/view/operation/currentWorkx"),
            },
            {
              path: "/currentWorkb",
              name: "currentWorkb",
              component: () => import("@/view/operation/currentWorkb"),
            },
            {
              path: "/currentWorkw",
              name: "currentWorkw",
              component: () => import("@/view/operation/currentWorkw"),
            },
          ],
        },
        {
          path: "/businessAdmin",
          name: "BusinessAdmin",
          component: () => import("@/view/base/businessAdmin2.0"),
        },
        {
          path: "/concentrator",
          name: "concentrator",
          component: () => import("@/view/base/concentrator"),
        },
        {
          path: "/equipmentManagement",
          name: "equipmentManagement",
          component: () => import("@/view/base/equipmentManagement"),
        },
        {
          path: "/districtManagement",
          name: "districtManagement",
          component: () => import("@/view/base/districtManagement"),
        },
        {
          path: "/addressBook",
          name: "addressBook",
          component: () => import("@/view/base/addressBook2.0"),
        },
        {
          path: "/gaugingManagement",
          name: "gaugingManagement",
          component: () => import("@/view/base/gaugingManagement"),
        },
        {
          path: "/drawing",
          name: "drawing",
          component: () => import("@/view/base/drawing"),
        },
        {
          path: "/allsInfo",
          name: "allsInfo",
          component: () => import("@/view/base/allsInfo"),
        },
        {
          path: "/electricityPrice",
          name: "electricityPrice",
          component: () => import("@/view/base/electricityPrice"),
        },
        {
          path: "/electricMeter",
          name: "electricMeter",
          component: () => import("@/view/base/electricMeter"),
        },
        {
          path: "/tenant",
          name: "tenant",
          component: () => import("@/view/base/tenant"),
        },
        {
          path: "/parameter",
          name: "parameter",
          component: () => import("@/view/system/parameter"),
        },
        {
          path: "/operation-ticket",
          name: "operation-ticket",
          component: () => import("@/view/operation/operation-ticket"),
        },
        {
          path: "/outages",
          name: "outages",
          component: () => import("@/view/operation/outages"),
        },
        {
          path: "/maintain-project",
          name: "maintain-project",
          component: () => import("@/view/operation/maintain-project"),
        },
        {
          path: "/maintain",
          name: "maintain",
          component: () => import("@/view/operation/maintain"),
        },
        {
          path: "/role",
          name: "role",
          component: () => import("@/view/system/role"),
        },
        {
          path: "/user",
          name: "user",
          component: () => import("@/view/system/user"),
        },

        {
          path: "/roleOutside",
          name: "roleOutside",
          component: () => import("@/view/system/role-outside"),
        },
        {
          path: "/userOutside",
          name: "userOutside",
          component: () => import("@/view/system/user-outside"),
        },
        {
          path: "/post",
          name: "post",
          component: () => import("@/view/system/post"),
        },
        {
          path: "/menu",
          name: "menu",
          component: () => import("@/view/system/menu"),
        },
        {
          path: "/dict",
          name: "dict",
          component: () => import("@/view/system/dict"),
        },
        {
          path: "/operationLog",
          name: "operationLog",
          component: () => import("@/view/system/operationLog"),
        },
        {
          path: "/department",
          name: "department",
          component: () => import("@/view/system/department"),
        },
        {
          path: "/thirdPartyClient",
          name: "thirdPartyClient",
          component: () => import("@/view/system/thirdPartyClient"),
        },
        {
          path: "/404",
          name: "404",
          component: () => import("@/view/errorPage/404"),
        },

        //2.0新增公共路由
        {
          //业主端首页
          path: "/home",
          name: "home",
          component: () => import("@/view/userindex"),
        },
        {
          //跳转保存参数页面
          path: "/station",
          name: "station",
          component: () => import("@/view/station"),
        },
        {
          //数据分析-实时数据
          path: "/realTimeData",
          name: "realTimeData",
          component: () => import("@/view/owner/analysis/realTimeData"),
        },
        {
          //数据分析-历史数据
          path: "/historydata",
          name: "historyData",
          component: () => import("@/view/owner/analysis/historyData.vue"),
        },
        {
          //数据分析-时间对比
          path: "/timeComparison",
          name: "timeComparison",
          component: () => import("@/view/owner/analysis/timeComparison.vue"),
        },
        {
          //数据分析-设备对比
          path: "/equipmentComparison",
          name: "equipmentComparison",
          component: () =>
            import("@/view/owner/analysis/equipmentComparison.vue"),
        },
        {
          //报警查询-历史报警
          path: "/historicalAlarm",
          name: "historicalAlarm",
          component: () => import("@/view/owner/alarm/historical-alarm.vue"),
        },
        {
          //用电分析-用量报表
          path: "/usageRport",
          name: "usageRport",
          component: () =>
            import("@/view/owner/electricityAnalysis/usageRport.vue"),
        },
        {
          //用电分析-同比分析
          path: "/YOYanalysis",
          name: "YOYanalysis",
          component: () =>
            import("@/view/owner/electricityAnalysis/YOYanalysis.vue"),
        },
        {
          //用电分析-环比分析
          path: "/MOManalysis",
          name: "MOManalysis",
          component: () =>
            import("@/view/owner/electricityAnalysis/MOManalysis.vue"),
        },
        {
          //用电分析-电能集抄
          path: "/powerMeter",
          name: "powerMeter",
          component: () =>
            import("@/view/owner/electricityAnalysis/powerMeter.vue"),
        },
        {
          //用电分析-能效报表
          path: "/energyEReport",
          name: "energyEReport",
          component: () =>
            import(
              "@/view/owner/electricityAnalysis/energyEReport.vue"
            ),
        },
        {
          //负荷分析-负荷率
          path: "/loadRate",
          name: "loadRate",
          component: () => import("@/view/owner/loadAnalysis/loadRate.vue"),
        },
        {
          //负荷分析-负荷报表
          path: "/loadReport",
          name: "loadReport",
          component: () => import("@/view/owner/loadAnalysis/loadReport.vue"),
        },
      ],
    },
  ],



});

export default router