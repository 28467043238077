<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    //  获取sessionStorage中存进去的type

    /**
     *  判断 sessionStorage 中的 type 是否为空，如果为空的话，就给默认的颜色（页面初始化的颜色），如
     *  果不为空的话就将对应获取到的值给到 data-theme
     **/

    if (sessionStorage.getItem('currentSkin') != null) {
      sessionStorage.setItem(
        'currentSkin',
        sessionStorage.getItem('currentSkin')
      )
      // 全局添加样式名称：data-theme，并在sessionStorage获取对应的样式名称的值。

      if (sessionStorage.getItem('currentSkin') == 'dark') {
      this.common.BGDark();
      } else {
       this.common.BGbright();
      }
    } else {
      // 全局添加样式名称：data-theme，并在sessionStorage获取对应的样式名称的值。
      // sessionStorage.setItem('currentSkin', 'bright')
      
      this.common.BGbright();
    }
    if (sessionStorage.getItem('stationName') != null) {
      document.title = sessionStorage.getItem('stationName')
    } else {
      document.title = '电力智能化运维平台2.0'
    }
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      isRouterAlive: true,
      deviceWidth: '',
    }
  },
  mounted() {
    // document.documentElement.style.fontSize = document.documentElement.clientWidth / 1920 * 100 + 'px';
    let that = this
    // if (window.addEventListener) {
    // 	window.addEventListener('resize', function() {
    // 		that.setHtmlFontSize()
    // 	}, false)
    // }


    var width = window.document.documentElement.getBoundingClientRect().width

    var rem = width / 19.2
    // var rem = width / 25.6;
    // 		if (width==1920) {
    // 			 rem = width / 25.6;
    // 		}else{
    //  rem = width / 20.48;
    // 		}

    document.getElementsByTagName('html')[0].style.cssText =
      'font-size:' + rem + 'px'

    var clientWidthh

    window.onresize = () => {
      // 浏览器窗口变化后需要做的事情
      return (() => {
        window.clientWidth = document.body.clientWidth
        this.clientWidth = window.clientWidth
        clientWidthh = this.clientWidth
        document.documentElement.style.setProperty(
          '--clientWidthh',
          this.clientWidth / 2 - 200 + 'px'
        )
        if (clientWidthh >= 700 && clientWidthh <= 1300)
          document.documentElement.style.setProperty(
            '--clientWidthh',
            this.clientWidth / 2 - 300 + 'px'
          )
        if (clientWidthh >= 1300 && clientWidthh <= 1700)
          document.documentElement.style.setProperty(
            '--clientWidthh',
            this.clientWidth / 2 - 100 + 'px'
          )
        if (clientWidthh >= 1700 && clientWidthh < 1800)
          document.documentElement.style.setProperty(
            '--clientWidthh',
            this.clientWidth / 2 + 'px'
          )
        if (clientWidthh >= 1800)
          document.documentElement.style.setProperty('--clientWidthh', 'auto')
        
      })()
    }

    window.addEventListener('resize', ()=> {
      // 变化后需要做的事
      that.setHtmlFontSize()

   
      if (sessionStorage.getItem('currentSkin') != null) {
        sessionStorage.setItem(
          'currentSkin',
          sessionStorage.getItem('currentSkin')
        )

        // 全局添加样式名称：data-theme，并在sessionStorage获取对应的样式名称的值。

        if (sessionStorage.getItem('currentSkin') == 'dark') {
         
           this.common.BGDark();
        } else {

 
          this.common.BGbright();
        }
      } else {
        // 全局添加样式名称：data-theme，并在sessionStorage获取对应的样式名称的值。
        // sessionStorage.setItem('currentSkin', 'bright')
       this.common.BGbright();
      }
    })

  },
  methods: {
    setSkin() {},
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    },
    setHtmlFontSize() {
    
      document.getElementsByTagName('html')[0].style.cssText =
        'font-size:' +
        document.documentElement.clientWidth / 19.2 +
        'px !important'

      
    },
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}

:root {
  --theme_bg_color: #ffffff;
  --theme_font_color: black;
  --theme_font_left_color: #ffffff;
  --theme_submenu_color: #1d4c9e;
  --theme_active_color: #23aaea;
  --theme_tagName_color: #c7cfde;
  --theme_tagName_font_color: #6b6f89;
  --theme_tagName_hover_color: #8691a7;
  --theme_bg_left_color: #2a58ad;
  --theme_bg_content_color: #f0f3f8;
  --theme_bg_tab_color: #e2e7f1;
  --clientWidthh: auto;
}
html,
body,
#app {
  height: 100%;
  overflow: hidden;
}
</style>
