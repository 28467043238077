/*
 * 配置编译环境和线上环境之间的切换
 * 以及静态变量
 * baseUrl: 域名地址
 * routerMode: 路由模式
 */

let baseUrl = "";

if (process.env.NODE_ENV === "development") {
  //本地测试环境

  // baseUrl = "http://192.168.20.153:88/";
  // baseUrl = "https://test.szphdl.com/";
  baseUrl = "http://cloud-s2.szphdl.com/";
} else if (process.env.NODE_ENV === "production") {
  //生产环境地址
  // baseUrl = "https://test.szphdl.com/";
  baseUrl = "";
}

export default baseUrl;
