import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import common from './utils/common.js'

import VueMqtt from 'vue-mqtt'
import iView from 'view-design'
import { Message } from 'iview'
import { v4 as uuidv4 } from 'uuid'
import 'view-design/dist/styles/iview.css'
import './assets/global.css'
import './assets/base_font.css'
import VeLine from 'v-charts/lib/line.common'
import VCharts1 from 'v-charts'

import moment from 'moment'
import less from 'less'
import echarts from 'echarts'
import global_ from './components/global'
import { post, get,getType } from './utils/http'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import qs from 'qs'
import hevueImgPreview from 'hevue-img-preview'
Vue.use(hevueImgPreview)
// import pdfMake from 'pdfmake/build/pdfmake'
// import pdfFonts from "pdfmake/build/vfs_fonts"
// import pdfFonts from '../public/static/vfs_fonts.js'
// import mitt from 'mitt';
import VCharts from 'v-charts-v2'
// import vueToPdf from 'vue-to-pdf';
// Vue.use(vueToPdf);
// const emitter = mitt();
// Vue.prototype.$emitter = emitter;
// pdfMake.vfs = pdfFonts.pdfMake.vfs

// pdfMake.fonts = {
//   Roboto: {
//     normal: 'Roboto-Regular.ttf',
//     bold: 'Roboto-Medium.ttf',
//     italics: 'Roboto-Italic.ttf',
//     bolditalics: 'Roboto-Italic.ttf',
//   },
//   幼圆: {
//     normal: '幼圆.TTF',
//     bold: '幼圆.TTF',
//     italics: '幼圆.TTF',
//     bolditalics: '幼圆.TTF',
//   },
// }

Vue.use(VCharts)
Vue.use(ElementUI)
Vue.use(VCharts1)

import Print from 'vue-print-nb'

// import rem from 'amfe-flexible'
// Vue.use(rem)
// import './utils/flexible'
// import './utils/flexible'
import 'video.js/dist/video-js.css'
import videojshotkeys from 'videojs-hotkeys'
Vue.use(videojshotkeys)
Vue.prototype.$post = post
Vue.prototype.$getType = getType
Vue.prototype.$get = get
Vue.prototype.common = common
Vue.prototype.GLOBAL = global_
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.prototype.$qs = qs
Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.use(router)
Vue.use(iView)
Vue.use(less)
Vue.use(Print)
Vue.component(VeLine.name, VeLine)
Vue.prototype.$eventBus = new Vue();

function getCookie(name) {
  var arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')

  if ((arr = document.cookie.match(reg))) return unescape(arr[2])
  else return null
}

//路由全局前置守卫
router.beforeEach((to, from, next) => {
  console.log(to, from,'===路由');
  if (to.path === "/currentWork/currentAlarm" || to.path === "/historyCurrentWork/currentAlarm" || to.path === "/projectS/currentAlarm" ){
    return next("/currentAlarm")
  }
  

  if (to.path === '/' && to.name === 'userLogin' ){
    sessionStorage.setItem('grant', 'out')
    common.delete('usertoken')
    return next()
  }
  if (to.path === '/admin'){
    sessionStorage.setItem('grant', 'in')
    common.delete('token')
    return next()
  }
  
  if (sessionStorage.getItem('grant') === 'out') {
    if (getCookie('usertoken') === null && !getCookie('userrememberMe')) {
      Message.warning('请先登录!')
      return next('/')
    }
  } else if(sessionStorage.getItem('grant') == 'in'){
    if (getCookie('token') === null && !getCookie('rememberMe')) {
      Message.warning('请先登录!')
      return next('/admin')
    }
  }else{

    return next('/')
  }

  let arr=[]
  let pathR = [
    '/historyCurrentWork/',
    '/historyCurrentWork',
    '/historyCurrentWorkB',
    '/historyCurrentWorkW',
    '/projectS/',
    '/projectS',
    '/projectStation',
    '/currentWork/',
    '/currentWork',
    '/currentWorkb',
    '/currentWorkw',
    '/station',
    // '/home',


    // '/electricDrawing',
    // '/realTimeData',
    // '/historydata',
    // '/timeComparison',
    // '/equipmentComparison',
    // '/historicalAlarm',
    // '/usageRport',
    // '/YOYanalysis',
    // '/MOManalysis',
    // '/powerMeter',
    // '/energyEReport',
    // '/loadRate',
    // '/loadReport',
    // '/inverter',
    // '/batteryPack',
    // '/circuitBreaker',
    // '/fan',
    // '/IndustrialAirC',
  ]

  let CRouter = [
    "/electricDrawing",
    "/realTimeData",
    "/historydata",
    "/timeComparison",
    "/equipmentComparison",
    "/historicalAlarm",
    "/usageRport",
    "/YOYanalysis",
    "/MOManalysis",
    "/powerMeter",
    "/energyEReport",
    "/loadRate",
    "/loadReport",
    // "/monthlyReport",
    "/inverter",
    "/batteryPack",
    "/circuitBreaker",
    "/fan",
    "/IndustrialAirC",

    // '/station','/','/index1','/index2','/home'
  ]


  let routerList = JSON.parse(sessionStorage.getItem('routerList'))==null?[]:JSON.parse(sessionStorage.getItem('routerList'));
  let UserRouterList = JSON.parse(sessionStorage.getItem('UserRouterList'))==null?[]:JSON.parse(sessionStorage.getItem('UserRouterList'));
 
  if ((sessionStorage.getItem('grant') === 'out' || sessionStorage.getItem('stationId') )) {
    sessionStorage.setItem('isType', 'c');
  }else{
    sessionStorage.setItem('isType', 'b');
  }

  let routerListFlag=[]

  if (sessionStorage.getItem('isType') === 'c') {
    
    if(sessionStorage.getItem("stationType") == 1){
      arr  = ['/','/index1'];
    }else if(sessionStorage.getItem("stationType") == 2){
      arr  = ['/','/index2'];
    }else{
      arr  = ['/','/index1','/index2'];
    }
  
  
    routerList.forEach((el)=>{
      [...CRouter,"/monthlyReport"].forEach((el1)=>{
        if (el == el1) {
          routerListFlag.push(el)
          }
      })
     })



  }else{
    arr  = ['/', '/index']
   

    
    routerList.forEach(item => {
        if (!CRouter.includes(item)) {
          routerListFlag.push(item);
        }
    });
    
    CRouter.forEach(item => {
        if (!routerList.includes(item)) {
          routerListFlag.push(item);
        }
    });
    



  }
if (sessionStorage.getItem('grant') === 'out' && !sessionStorage.getItem('stationId')) {
  UserRouterList=[];
  arr  = ['/','/home'];
}

    let routerList1 = [...arr, ...pathR, ...routerListFlag , ...UserRouterList];

    let index = routerList1.indexOf(to.path)
    if (!(index > -1)) {
      // 没有权限
      //防止无限循环
      if (to.path === '/404') {
        next()
        return
      }
      next({
        path: '/404',
      })
    } else {
   
      next()
    }
})

let URL = window.location.href
let UrlList = URL.split('/')
Vue.filter('dateformat', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
let MQTT_MQTTIP
if (process.env.NODE_ENV === 'development') {
  // MQTT_MQTTIP = `ws://192.168.137.105:88//ws` //外网
  MQTT_MQTTIP = `ws://cloud-s2.szphdl.com//ws` //外网
} else if (process.env.NODE_ENV === 'production') {
  MQTT_MQTTIP = UrlList[0].indexOf('https') != -1 ? `wss://${UrlList[2]}/ws` : `ws://${UrlList[2]}/ws`
}

// export const MQTT_MQTTIP = `web:192.168.20.153:1884/ws`

// export const MQTT_MQTTIP =  `ws://192.168.137.106:1884//ws` ; //外网

let clientId = uuidv4()
store.state.clientId = clientId
Vue.use(VueMqtt, MQTT_MQTTIP, {
  clientId: clientId,
  username: 'koyoe',
  password: 'koyoe123', // Passwords are buffers
})
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
  template: '<App/>',
  data: {
    eventHub: new Vue(),
  },
}).$mount('#app')
